<template>
  <v-container
    id="contact"
    fluid
    tag="section"
  >
  </v-container>
</template>

<script>
  export default {
    name: 'Contact',
    data () {
      return {}
    },
  }
</script>
<style>
#contact {
  margin-top: 300px;
}
</style>
